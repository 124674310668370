import React from 'react'
import { graphql } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'

import ProductGrid from '../../components/ProductGrid'

const CollectionPage = ({ pageContext, data }) => {

  return (
    <>
      <Box p={2}>
        <h3>All {pageContext.handle} Gifts</h3>
      </Box>
      <ProductGrid products={data.allShopifyProduct.edges}/>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    allShopifyProduct(
      sort: {
        fields: [createdAt]
        order: DESC
      },
      filter: {
        productType: {eq: $handle}
      }
    ) {
      edges {
        node {
          id
          title
          handle
          productType
          createdAt
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          variants {
            price
          }
        }
      }
    }
  }
`

export default CollectionPage
